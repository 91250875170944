
import {request} from "@/network/request.js";
import axios from 'axios'
import qs from 'qs';

export default{
  // 查询-商品类别
  findAllProductTypeNoChild(data){
    return request({
      method:'POST',
      url:'/system/dictionary/findAllProductTypeNoChild',
      data
    })
  },
  //查询-采购单据类型
  findByTypeId(data){
    return request({
      method:'POST',
      url:'/system/findByTypeId',
      data
    })
  },
  //查询-区域
  findArea(params){
    return axios({
      method: "get",
      url:'https://plugin.yingyanso.net/area',
      params
    })
  },
  //查询-条件查询销售汇总信息
  findSalesReport(data){
    return request({
      method:'POST',
      url:'/report/sales/findSalesReport',
      data
    })
  },
  // 查询-根据客户条件查询销售统计列表信息
  findSalesByCustomerListReport(data){
    return request({
      method:'POST',
      url:'/report/sales/findSalesByCustomerListReport',
      data
    })
  },
  // 查询-根据产品条件查询销售统计列表信息
  findSalesByProductListReport(data){
    return request({
      method:'POST',
      url:'/report/sales/findSalesByProductListReport',
      data
    })
  },
  // 查询-根据品牌条件查询销售统计列表信息
  findSalesByBrandListReport(data){
    return request({
      method:'POST',
      url:'/report/sales/findSalesByBrandListReport',
      data
    })
  },
  // 查询-根据来源条件查询销售统计列表信息
  findSalesBySourceListReport(data){
    return request({
      method:'POST',
      url:'/report/sales/findSalesBySourceListReport',
      data
    })
  },
}